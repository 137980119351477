
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { Link } from "@mui/material";
import useTranslation from "next-translate/useTranslation";

export default function Custom404() {
  const { t } = useTranslation('common');

  return <>
    <h1>{t('page_not_found')}</h1>

    <p>
      <Link href="/">
        {t('back_to_dashboard')}
      </Link>
    </p>
  </>
}

    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  